// this is only used to avoid major refactors through the codebase,
// because for some reason both fetch and axios is used

export const defaultHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

function customFetch(url, options = {}) {
  const token = localStorage.getItem("token");

  const headers = {
    ...defaultHeaders,
    // Add the Authorization header if the token exists
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...options.headers,
  };

  return fetch(url, {
    ...options,
    headers,
  });
}

export default customFetch;
