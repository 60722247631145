import Swal from "sweetalert2";

export function showErrorMessage(response) {
  const getFirstError = (obj) => {
    if (obj) {
      if (typeof obj === "object") {
        for (const key in obj) {
          if (obj[key]) {
            return obj[key];
          }
        }
      } else {
        return obj;
      }
    }
    return null;
  };

  const errorMsg =
    getFirstError(response.errors) ||
    getFirstError(response.messages) ||
    response.message;

  if (errorMsg) showError(errorMsg);
}

export function showError(errorMsg) {
  Swal({
    type: "error",
    title: "Oops...",
    text: errorMsg,
  });
}
