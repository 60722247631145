<template>
  <div id="app">
    <Loader :loading="showLoader" />
    <router-view></router-view>
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";
import { EventBus } from "./utils/EventBus";

export default {
  name: "app",
  components: {
    Loader,
  },
  data() {
    return {
      showLoader: false,
    };
  },
  created() {
    // Listen for loader-related events
    EventBus.$on("loading", status => {
      this.showLoader = status;
    });
  },
};
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
</style>
