import axios from "axios";
import { showErrorMessage } from "./errorHandler";
import { EventBus } from "./EventBus";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

// Create axios instance with default config
const axiosInstance = axios.create({
  headers: defaultHeaders
});

axiosInstance.interceptors.request.use(
  (config) => {
    EventBus.$emit("loading", true);
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    EventBus.$emit("loading", false);
    // Only show error if suppressError is not set
    if (!error.config?.suppressError) {
      showError('An unexpected server error occurred');
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    EventBus.$emit("loading", false);
    return response;
  },
  (error) => {
    EventBus.$emit("loading", false);

    // Only show error message if suppressError is not set
    if (!error.config?.suppressError) {
      showErrorMessage(error.response.data);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;