<template>
  <section class="top-bar-new row pt-5 mt-3">
    <b-navbar id="template-header" class="
      navbar
      default-layout-navbar
      col-lg-12 col-12
      p-0
      fixed-top
      d-flex
      flex-row
    " toggleable="lg">
      <div class="
        text-center
        navbar-brand-wrapper
        d-flex
        align-items-top
        justify-content-center
      ">
        <!-- <router-link class="navbar-brand brand-logo-mini" to="/">
          <img src="@/assets/images/logo-new.png" alt="logo" />
        </router-link> -->
        <router-link class="navbar-brand brand-logo" to="/">
          <img src="@/assets/images/logo-new.png" alt="logo" />
        </router-link>
        <router-link class="navbar-brand brand-logo-mini" to="/">
          <img src="@/assets/images/logo-mini.png" alt="logo" />
        </router-link>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
        <button class="navbar-toggler navbar-toggler align-self-center d-lg-block" type="button"
          @click="toggleSidebar()">
          <span class="mdi mdi-menu"></span>
        </button>

        <b-navbar-nav class="navbar-nav-right ml-auto">
          <!-- <b-nav-item-dropdown right class="preview-list">
            <template slot="button-content">
              <div class="nav-link count-indicator dropdown-toggle">
                <i class="mdi mdi-bell-outline"></i>
                <span class="count-symbol bg-danger"></span>
              </div>
            </template>
            <h6 class="p-3 mb-0">Notifications</h6>
            <b-dropdown-item class="preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-success">
                  <i class="mdi mdi-calendar"></i>
                </div>
              </div>
              <div class="
                preview-item-content
                d-flex
                align-items-start
                flex-column
                justify-content-center
              ">
                <h6 class="preview-subject font-weight-normal mb-1">
                  Event today
                </h6>
                <p class="text-gray ellipsis mb-0">
                  Just a reminder that you have an event today
                </p>
              </div>
            </b-dropdown-item>
            <b-dropdown-item class="preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-warning">
                  <i class="mdi mdi-settings"></i>
                </div>
              </div>
              <div class="
                preview-item-content
                d-flex
                align-items-start
                flex-column
                justify-content-center
              ">
                <h6 class="preview-subject font-weight-normal mb-1">Settings</h6>
                <p class="text-gray ellipsis mb-0">Update dashboard</p>
              </div>
            </b-dropdown-item>
            <b-dropdown-item class="preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-info">
                  <i class="mdi mdi-link-variant"></i>
                </div>
              </div>
              <div class="
                preview-item-content
                d-flex
                align-items-start
                flex-column
                justify-content-center
              ">
                <h6 class="preview-subject font-weight-normal mb-1">
                  Launch Admin
                </h6>
                <p class="text-gray ellipsis mb-0">New admin wow!</p>
              </div>
            </b-dropdown-item>
            <h6 class="p-3 mb-0 text-center border-top">4 new messages</h6>
          </b-nav-item-dropdown> -->
          <!-- <b-nav-item-dropdown right class="nav-profile" v-if="user">
          <template slot="button-content">
            <span class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:void(0);"
              data-toggle="dropdown" aria-expanded="false">
              <div class="nav-profile-img">
                <img v-if="user.image_id" :src="`${imageApiURL}/${info.path}`" alt="image" />
                <avatar v-else :username="user.first_name" background-color="#eb2027" :size="40">
                </avatar>
                <span class="availability-status online"></span>
              </div>
              <div class="nav-profile-text">
                <p class="mb-1 text-black text-capitalize">{{ user.first_name }}</p>
                <span class="text-secondary text-capitalize text-small">{{ user.role[0] }}</span>
              </div>
            </span>
          </template>
        </b-nav-item-dropdown> -->
          <b-nav-item-dropdown right class="nav-profile" v-if="user">
            <template slot="button-content">
              <span class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:void(0);"
                data-toggle="dropdown" aria-expanded="false">
                <div class="nav-profile-img">
                  <img v-if="user.image_id" :src="`${imageApiURL}/${info.path}`" alt="image" />
                  <avatar v-else :username="user.first_name" background-color="#eb2027" :size="40">
                  </avatar>
                  <span class="availability-status online"></span>
                </div>
                <div class="nav-profile-text">
                  <p class="mb-1 text-capitalize">{{ user.first_name }}</p>
                  <span class="text-capitalize text-small">{{ user.role[0] }}</span>
                </div>
                <div class="float-right pl-3">
                  <i class="mdi mdi-chevron-down"></i>
                </div>
              </span>
            </template>
            <!-- <b-dropdown-item class="preview-item">
            <i class="mdi mdi-cached mr-2 text-success"></i> Activity Log
          </b-dropdown-item> -->
            <b-dropdown-item :to="`/users/user-info/${user.id}`" class="preview-item ">
              <i class="mdi mdi-account mr-2"></i> <span>My Account</span>
            </b-dropdown-item>
            <b-dropdown-item v-on:click="logOut" class="preview-item ">
              <i class="mdi mdi-logout mr-2 "></i> <span>Signout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item class="nav-logout">
          <span v-on:click="logOut" href="javascript:void(0);">
            <i class="mdi mdi-power"></i></span>
        </b-nav-item> -->
        </b-navbar-nav>
        <button class="navbar-toggler navbar-toggler-right align-self-center" type="button"
          @click="toggleMobileSidebar()">
          <span class="mdi mdi-menu"></span>
        </button>
      </div>
    </b-navbar>
  </section>
</template>

<script>
import axios from "axios";
import { userApi } from "../../api";

import Avatar from 'vue-avatar'
const baseUrl = process.env.VUE_APP_BASE_URL
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
export default {
  name: "app-header",
  components: {
    Avatar,
    
  },
  data() {

    return {
      info: "",
      imageApiURL,
      user: null,
      
      user_id: localStorage.getItem("user_id"),
    }
  },
  mounted() {
    this.listById();
  },
  methods: {
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
    },
    // async listById() {
    //   
    //   const { data } = await userApi.listById(this.user_id);
    //   this.user = data.data.data;
    //   if (this.user.image_id) {
    //     this.avater();
    //   }

    //   
    // },
    listById() {
      
      axios.get(`${baseUrl}/api/accounts/user/${this.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
      ).then((response) => {
        this.user = response.data.data;
        if (this.user.image_id) {
          this.avater();
        }
      })
        .catch(err => {
          console.log("err", err.response.status)
          if (err.response.status === 500 || err.response.status === 401) {
            this.$router.push("/auth-pages/login");
          }
        }
        );

      
    },
    avater() {
      
      axios.get(`${mediaApi}/files/${this.user.image_id}`).then((response) => {
        this.info = response.data.data[0];
        
      });
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      this.$router.push("/auth-pages/login");
    },
  },
};
</script>

<style scoped>
</style>