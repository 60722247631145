const AUTH_API = process.env.VUE_APP_AUTH_SERVICE;
import axiosInstance from "../utils/axios";

export const authApi = {
  async signUp(user) {
    try {
      return await axiosInstance.post(`${AUTH_API}/register`, user);
    } catch (error) {
      return error.response;
    }
  },

  async login(user) {
    try {
      return await axiosInstance.post(`${AUTH_API}/login`, user);
    } catch (error) {
      return error.response;
    }
  },

  async forgotPassword(email) {
    try {
      return await axiosInstance.post(`${AUTH_API}/reset-password`, { email });
    } catch (error) {
      return error.response;
    }
  },

  async resetPassword(user) {
    try {
      return await axiosInstance.post(`${AUTH_API}/reset`, user);
    } catch (error) {
      return error.response;
    }
  },

  async countries() {
    try {
      return await axiosInstance.get(`${AUTH_API}/countries/countries`, {
        suppressError: true,
      });
    } catch (error) {
      return error.response;
    }
  },
};
