const CATALOG_API = process.env.VUE_APP_CATALOG_SERVICE;
import axiosInstance from "../utils/axios";
import customFetch from "../utils/customFetch";

export const catalogApi = {
  //Category module
  async allCategory() {
    const response = await customFetch(`${CATALOG_API}/categories`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response.json();
    return {
      ...data,
      status: response.status,
    };
  },
  async categoryById(id) {
    const response = await customFetch(`${CATALOG_API}/category/${id}`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response.json();
    return {
      ...data,
      status: response.status,
    };
  },
  async addCategory(category) {
    try {
      return await axiosInstance.post(
        `${CATALOG_API}/category/create`,
        category
      );
    } catch (error) {
      return error.response;
    }
  },
  async deleteCategory(id) {
    const response = await customFetch(`${CATALOG_API}/category/delete`, {
      method: "POST",
      data: JSON.stringify({ id }),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async updateCategory(id, category) {
    const response = await customFetch(`${CATALOG_API}/category/update/${id}`, {
      method: "POST",
      body: JSON.stringify(category),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },

  //Stores module
  async getStores(page) {
    try {
      const response = await axiosInstance(
        `${CATALOG_API}/stores?page=${page}`,
        {
          method: "GET",
        }
      );
      const data = await response;
      return {
        data,
        status: response.status,
      };
    } catch (error) {
      console.error("Error:", error.message);
      return {
        data: null,
        status: error.response ? error.response.status : null,
      };
    }
  },

  async getStoresById(id) {
    const response = await customFetch(`${CATALOG_API}/store/${id}`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response.json();
    return {
      data,
      status: response.status,
    };
  },
  async deleteStores(id) {
    const response = await customFetch(`${CATALOG_API}/store/delete`, {
      method: "POST",
      data: JSON.stringify({ id }),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async addStore(store) {
    const response = await customFetch(`${CATALOG_API}/store/create`, {
      method: "POST",
      body: JSON.stringify(store),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async updateStore(id, store) {
    const response = await customFetch(`${CATALOG_API}/store/update/${id}`, {
      method: "POST",
      body: JSON.stringify(store),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },

  //Brands module
  async getBrands(page) {
    try {
      const response = await axiosInstance(
        `${CATALOG_API}/brands?page=${page}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      return {
        data,
        status: response.status,
      };
    } catch (error) {
      console.error("Error:", error.message);
      return {
        data: null,
        status: error.response ? error.response.status : null,
      };
    }
  },
  async getBrandById(id) {
    const response = await customFetch(`${CATALOG_API}/brand/${id}`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response.json();
    return {
      data,
      status: response.status,
    };
  },
  async deleteBrand(id) {
    const response = await customFetch(`${CATALOG_API}/brand/delete`, {
      method: "POST",
      data: JSON.stringify({ id }),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async addBrand(brand) {
    const response = await customFetch(`${CATALOG_API}/brand/create`, {
      method: "POST",
      body: JSON.stringify(brand),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async updateBrand(id, brand) {
    const response = await customFetch(`${CATALOG_API}/brand/update/${id}`, {
      method: "POST",
      body: JSON.stringify(brand),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },

  //Tags module
  async getTags(page) {
    try {
      const response = await axiosInstance(`${CATALOG_API}/tags?page=${page}`, {
        method: "GET",
      });
      const data = await response;
      return {
        data,
        status: response.status,
      };
    } catch (error) {
      console.error("Error:", error.message);
      return {
        data: null,
        status: error.response ? error.response.status : null,
      };
    }
  },
  async getTagById(id) {
    const response = await customFetch(`${CATALOG_API}/tag/${id}`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async deleteTag(id) {
    const response = await customFetch(`${CATALOG_API}/tag/delete`, {
      method: "POST",
      data: JSON.stringify({ id }),
    }).catch((err) => console.error("err", err.message));
    const json = await response;
    return {
      ...json,
      status: response.status,
    };
  },
  async addTag(tag) {
    const response = await customFetch(`${CATALOG_API}/tag/create`, {
      method: "POST",
      body: JSON.stringify(tag),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async updateTag(id, tag) {
    const response = await customFetch(`${CATALOG_API}/tag/update/${id}`, {
      method: "POST",
      body: JSON.stringify(tag),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },

  //Products module
  // async getProducts(page) {
  //   const response = await customFetch(`${CATALOG_API}/products?page=${page}`, {
  //     method: "GET",
  //   }).catch(err => console.error("err", err.message));
  //   const data = await response
  //   console.log(headers);
  //   return {
  //     data,
  //     status: response.status
  //   };
  // },

  async getProducts(page) {
    try {
      const response = await axiosInstance.get(
        `${CATALOG_API}/products?page=${page}`
      );
      return {
        data: response,
        status: response.status,
      };
    } catch (error) {
      console.error("fazil:", error, "fazil", error.respons);
      return {
        data: null,
        status: error.response ? error.response.status : null,
      };
    }
  },

  async productSearch(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/product/search?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },

  async productsList() {
    const response = await customFetch(`${CATALOG_API}/products?list`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async productExport(parameter) {
    const response = await customFetch(
      `${CATALOG_API}/products/export?${parameter}`,
      {
        method: "GET",
        responseType: "blob",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async getProductById(id) {
    const response = await customFetch(`${CATALOG_API}/product/${id}`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async deleteProduct(id) {
    const response = await customFetch(`${CATALOG_API}/product/delete`, {
      method: "POST",
      data: JSON.stringify({ id }),
    }).catch((err) => console.error("err", err.message));
    const json = await response;
    return {
      ...json,
      status: response.status,
    };
  },
  async addProduct(product) {
    const response = await customFetch(`${CATALOG_API}/product/create`, {
      method: "POST",
      body: JSON.stringify(product),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },
  async updateProduct(id, product) {
    const response = await customFetch(`${CATALOG_API}/product/update/${id}`, {
      method: "POST",
      body: JSON.stringify(product),
    }).catch((err) => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status,
    };
  },

  async sortByBrands(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/products?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },

  async sortByStatus(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/products?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },

  async priceRange(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/products?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async idRange(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/products?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },

  async getBrandsByList() {
    const response = await customFetch(`${CATALOG_API}/brands?list`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async getTagsByList() {
    const response = await customFetch(`${CATALOG_API}/tags?list`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
  async getStoresByList() {
    const response = await customFetch(`${CATALOG_API}/stores?list`, {
      method: "GET",
    }).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },

  async sortDirection(parameter, page) {
    const response = await customFetch(
      `${CATALOG_API}/product/search?${parameter}&page=${page}`,
      {
        method: "GET",
      }
    ).catch((err) => console.error("err", err.message));
    const data = await response;
    return {
      data,
      status: response.status,
    };
  },
};
